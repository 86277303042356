import React from "react";


interface NumericalKeyboardButtonProps {
    digit: number;
    onAnswerDigit: (answer: number) => void;
}

const buttonStyle: React.CSSProperties = {
    width: '50px',       // Set a fixed width for all buttons
    height: '50px',       // Make buttons taller
    fontSize: '14px',     // Font size for longer text
    margin: '0 5px',      // Horizontal margin between buttons
    cursor: 'pointer',    // Pointer cursor for better UX
    borderRadius: '5px',  // Rounded corners
    border: '1px solid #ccc', // Border styling
    whiteSpace: 'nowrap', // Prevent wrapping of button text
};


const NumericalKeyboardButtonComponent: React.FC<NumericalKeyboardButtonProps> = ({ digit, onAnswerDigit }) => {
    return (
        <button
            style={buttonStyle}
            onClick={() => onAnswerDigit(digit)}
        >
            {digit}
        </button>
    )
}


interface NumericalKeyboardProps {
    onAnswerDigit: (answer: number) => void;
}


export const NumericalKeyboardComponent: React.FC<NumericalKeyboardProps> = ({onAnswerDigit}) => {
    const containerStyle: React.CSSProperties = {
        display: 'flex',      // Horizontal layout for buttons
        justifyContent: 'center',  // Center the buttons horizontally
        alignItems: 'center', // Align items vertically centered
        margin: '20px 0',     // Space above and below the buttons
        flexWrap: 'wrap',     // Wrap if necessary for smaller screens
    };

    return (
        <div>
            <div style={containerStyle}>
                <NumericalKeyboardButtonComponent digit={1} onAnswerDigit={onAnswerDigit} />
                <NumericalKeyboardButtonComponent digit={2} onAnswerDigit={onAnswerDigit} />
                <NumericalKeyboardButtonComponent digit={3} onAnswerDigit={onAnswerDigit} />
            </div>
            <div style={containerStyle}>
                <NumericalKeyboardButtonComponent digit={4} onAnswerDigit={onAnswerDigit} />
                <NumericalKeyboardButtonComponent digit={5} onAnswerDigit={onAnswerDigit} />
                <NumericalKeyboardButtonComponent digit={6} onAnswerDigit={onAnswerDigit} />
            </div>
            <div style={containerStyle}>
                <NumericalKeyboardButtonComponent digit={7} onAnswerDigit={onAnswerDigit} />
                <NumericalKeyboardButtonComponent digit={8} onAnswerDigit={onAnswerDigit} />
                <NumericalKeyboardButtonComponent digit={9} onAnswerDigit={onAnswerDigit} />
            </div>
            <div style={containerStyle}>
                <NumericalKeyboardButtonComponent digit={0} onAnswerDigit={onAnswerDigit} />
            </div>
        </div>
    )
}