import React, { useState } from 'react';
import { StringAnswerQuestion, StringResponse } from '../../model/question_models';

interface Props {
    question: StringAnswerQuestion;
    onAnswer: (answer: StringResponse) => void;
}

const StringAnswerQuestionComponent: React.FC<Props> = ({ question, onAnswer }) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Validate the input according to the regex
    const validateInput = (value: string): string | null => {
        if (!question.regex.test(value)) {
            return question.errorMessage;
        }
        return null;
    };

    // Handle input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);

        const validationError = validateInput(value);
        setErrorMessage(validationError);
    };

    // Handle submit
    const handleSubmit = () => {
        if (!errorMessage) {
            const response: StringResponse = {
                questionId: question.questionId,
                type: 'stringAnswer',
                enteredText: inputValue,
                duration: 0, // Placeholder for time duration tracking
                timerExpired: false,
            };
            onAnswer(response);
        }
    };

    const inputStyle: React.CSSProperties = {
        width: '300px',
        height: '40px',
        fontSize: '16px',
        margin: '10px 0',
        padding: '5px',
        borderRadius: '5px',
        border: errorMessage ? '1px solid red' : '1px solid #ccc',
    };

    const errorStyle: React.CSSProperties = {
        color: 'red',
        marginTop: '5px',
    };

    return (
        <div>
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                style={inputStyle}
            />
            {errorMessage && <div style={errorStyle}>{errorMessage}</div>}
            <button
                onClick={handleSubmit}
                disabled={errorMessage !== null || inputValue === ''}
                style={{ marginTop: '10px', padding: '10px 20px' }}
            >
                Submit
            </button>
        </div>
    );
};

export default StringAnswerQuestionComponent;
