import React, { Component } from 'react';
import { GUID, SurveyStartInfo } from "../../model/question_models";

// Props for the SurveyStartScreen component
interface SurveyStartScreenProps {
    survey: SurveyStartInfo;
    onStart: (surveyId: GUID) => void;
    onCancel: () => void;
}

// State for the SurveyStartScreen component (not used but defined for extensibility)
type SurveyStartScreenState = object

// The SurveyStartScreen class component
export class SurveyStartScreen extends Component<SurveyStartScreenProps, SurveyStartScreenState> {
    // Handler for starting the survey
    handleStartClick = () => {
        const { survey, onStart } = this.props;
        onStart(survey.surveyId);
    };

    handleBackClick = () => {
        const { onCancel } = this.props;
        onCancel();
    }

    render() {
        const { survey } = this.props;

        return (
            <div style={styles.container}>
                <h1 style={styles.title}>{survey.title}</h1>
                <p style={styles.summary}>{survey.summary}</p>
                <button style={styles.button} onClick={this.handleStartClick}>
                    Start Survey
                </button>

                <button style={styles.button} onClick={this.handleBackClick}>
                    Back
                </button>
            </div>
        );
    }
}

// Inline styles for simplicity; you can replace these with CSS or styled-components
const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2rem',
        maxWidth: '600px',
        margin: '0 auto',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f9f9f9',
    },
    title: {
        fontSize: '2rem',
        marginBottom: '1rem',
        textAlign: 'center',
    },
    summary: {
        fontSize: '1.2rem',
        marginBottom: '2rem',
        textAlign: 'center',
        color: '#555',
    },
    button: {
        padding: '0.75rem 1.5rem',
        fontSize: '1rem',
        color: '#fff',
        backgroundColor: '#007BFF',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};

export default SurveyStartScreen;
