import React from 'react';
import { YesNoUnsureQuestion, YesNoUnsureResponse } from '../../model/question_models';

interface Props {
    question: YesNoUnsureQuestion;
    onAnswer: (answer: YesNoUnsureResponse) => void;
}

const YesNoUnsureQuestionComponent: React.FC<Props> = ({ question, onAnswer }) => {

    // Handle the selection of Yes, No, or Unsure
    const handleChoiceSelect = (selectedAnswer: 'yes' | 'no' | 'unsure') => {
        const response: YesNoUnsureResponse = {
            questionId: question.questionId,
            type: 'yesNoUnsure',
            selectedAnswer: selectedAnswer,
            duration: 0, // Placeholder, can be used to track duration
            timerExpired: false, // User selected, so timer didn't expire
        };
        onAnswer(response);
    };

    // Styles for the buttons (same as MultipleChoice, but horizontal layout)
    const buttonStyle: React.CSSProperties = {
        width: '150px',       // Set a fixed width for all buttons
        height: '50px',       // Make buttons taller
        fontSize: '16px',     // Increase font size
        margin: '0 10px',     // Horizontal margin between buttons
        cursor: 'pointer',    // Pointer cursor for better UX
        borderRadius: '5px',  // Rounded corners
        border: '1px solid #ccc', // Border styling
    };

    const containerStyle: React.CSSProperties = {
        display: 'flex',      // Horizontal layout for buttons
        justifyContent: 'center',  // Center the buttons horizontally
        alignItems: 'center', // Align items vertically centered
        margin: '20px 0',     // Space above and below the buttons
    };

    if (!question.allowUnsure) {
        return (
            <div>
                <div style={containerStyle}>
                    <button
                        style={buttonStyle}
                        onClick={() => handleChoiceSelect('yes')}
                    >
                        Yes
                    </button>
                    <button
                        style={buttonStyle}
                        onClick={() => handleChoiceSelect('no')}
                    >
                        No
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div style={containerStyle}>
                <button
                    style={buttonStyle}
                    onClick={() => handleChoiceSelect('yes')}
                >
                    Yes
                </button>
                <button
                    style={buttonStyle}
                    onClick={() => handleChoiceSelect('no')}
                >
                    No
                </button>
                <button
                    style={buttonStyle}
                    onClick={() => handleChoiceSelect('unsure')}
                >
                    Don't know
                </button>
            </div>
        </div>
    );
};

export default YesNoUnsureQuestionComponent;
