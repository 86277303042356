import {GUID, Question, QuestionResponse, SurveyListItem, SurveyStartInfo} from '../../model/question_models';
import {
    getAvailableSurveys,
    getQuestions,
    getSessionIdFromServer,
    getSurveyInfo,
    postAnswers,
    startSurvey
} from "./rawApiClient";
import {Actor} from "../../helpers/actor"
import {URLSearchParams} from "node:url";
import {AwaitableObject} from '../../helpers/awaitable_object'

interface ApiClientState {
    sessionId?: string
    initialized: boolean
    mainUrlParams: URLSearchParams
}

interface PostQuestionResponseMessage {
    type: 'postAnswerMessage'
    answer: QuestionResponse
    surveyId: string
}

interface NewQuestions {
    type: 'newQuestions'
    newQuestions: Question[]
}

interface TestComplete {
    type: 'testComplete'
}

type NextTestStepResponse = NewQuestions | TestComplete;

interface GetNextTestStepMessage {
    type: 'getNextTestStep';
    surveyId: string
    responsePromise: AwaitableObject<NextTestStepResponse>;
}

interface GetAvailableSurveysMessage {
    type: 'getAvailableSurveys';
    responsePromise: AwaitableObject<SurveyListItem[]>;
}

interface GetSurveyMessage {
    type: 'getSurvey';
    surveyId: GUID;
    responsePromise: AwaitableObject<SurveyStartInfo>;
}

interface StartSurveyMessage {
    type: 'startSurvey';
    surveyId: GUID;
    responsePromise: AwaitableObject<SurveyStartInfo>;
}

type Message = PostQuestionResponseMessage | GetNextTestStepMessage | GetAvailableSurveysMessage | GetSurveyMessage | StartSurveyMessage;


interface UpdateCoreResponse {
    state: ApiClientState
    remainingMessages: Message[]
}

function countWhile<T>(arr: T[], predicate: (item: T) => boolean): number {
    let count = 0;
    for (const item of arr) {
        if (!predicate(item)) {
            break;
        }
        count++;
    }
    return count;
}

async function getNextTestStepResponse(sessionId: string, surveyId: string): Promise<NewQuestions> {
    console.log('get next test step')
    const questions = await getQuestions(sessionId, surveyId)

    console.log('got next test step from server')
    return {type: 'newQuestions', newQuestions: questions};
}

async function updateCore (state: ApiClientState, messages: Message[]): Promise<UpdateCoreResponse> {
    console.log('updateCore starting')

    if (state.sessionId === undefined) {
        console.log('no session id')
        const newState = {
            ...state,
            sessionId: await getSessionIdFromServer(state.mainUrlParams)
        }

        console.log('got new session id')
        return { state: newState, remainingMessages: messages }
    }

    if (messages.length === 0) {
        console.log('no messages to process. skipping')
        return { state: state, remainingMessages: [] }
    }

    const countSendResponseMessages = countWhile(messages, m => m.type === 'postAnswerMessage')
    if (countSendResponseMessages > 0) {
        console.log('sending response messages')
        const responsesToSend: PostQuestionResponseMessage[] = messages.slice(0, countSendResponseMessages) as PostQuestionResponseMessage[];
        const answersToSend = responsesToSend.map(r => r.answer)

        // todo: we are assuming all pending responses share the same survey id
        await postAnswers(state.sessionId, responsesToSend[0].surveyId, answersToSend)

        return { state: state, remainingMessages: messages.slice(countSendResponseMessages) }
    }

    const firstMessage = messages[0];

    switch(firstMessage.type)
    {
        case 'getNextTestStep':
            firstMessage.responsePromise.setResponse(await getNextTestStepResponse(
                state.sessionId, firstMessage.surveyId));
            break;
        case 'getAvailableSurveys':
            firstMessage.responsePromise.setResponse(await getAvailableSurveys(state.sessionId));
            break;
        case 'getSurvey':
            firstMessage.responsePromise.setResponse(await getSurveyInfo(state.sessionId, firstMessage.surveyId));
            break;
        case 'startSurvey':
            console.log('starting survey')
            firstMessage.responsePromise.setResponse(await startSurvey(state.sessionId, firstMessage.surveyId));
            break;
        default:
            console.error('unknown or unhandled message')
            throw new Error('Unknown or unhandled message')
    }

    return { state: state, remainingMessages: messages.slice(1) }
}

export class ApiClient {
    private actor: Actor<ApiClientState, Message>

    constructor(mainUrlParams: URLSearchParams) {
        const initialState: ApiClientState = {
            sessionId: undefined,
            initialized: false,
            mainUrlParams: mainUrlParams
        }

        this.actor = new Actor<ApiClientState, Message>(initialState, updateCore, (state: ApiClientState) => this.onStateChangedHandler(state))
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onStateChangedHandler(state: ApiClientState) {
        console.log('api state changed')
    }

    postQuestionResponseToServer(surveyId: string, message: QuestionResponse) {
        const m: PostQuestionResponseMessage = {
            type: 'postAnswerMessage',
            answer: message,
            surveyId: surveyId,
        }

        this.actor.sendMessage(m)
    }

    async listAvailableSurveys() : Promise<SurveyListItem[]> {
        console.log('listing available surveys')

        const m: GetAvailableSurveysMessage = {
            type: 'getAvailableSurveys',
            responsePromise: new AwaitableObject<SurveyListItem[]>()
        }

        this.actor.sendMessage(m)
        return await m.responsePromise.awaitResponse();
    }

    async getSurveyInfo(surveyId: GUID) : Promise<SurveyStartInfo> {
        console.log('getting survey')

        const m: GetSurveyMessage = {
            type: 'getSurvey',
            surveyId: surveyId,
            responsePromise: new AwaitableObject<SurveyStartInfo>()
        }

        this.actor.sendMessage(m)
        return await m.responsePromise.awaitResponse();
    }

    async startSurvey(surveyId: GUID) : Promise<SurveyStartInfo> {
        console.log('starting survey')

        const m: StartSurveyMessage = {
            type: 'startSurvey',
            surveyId: surveyId,
            responsePromise: new AwaitableObject<SurveyStartInfo>()
        }

        this.actor.sendMessage(m)
        return await m.responsePromise.awaitResponse();
    }

    async getNextTestStep(surveyId: string) : Promise<NextTestStepResponse> {
        console.log('getting next test step. begin')

        const m: GetNextTestStepMessage = {
            type: 'getNextTestStep',
            surveyId: surveyId,
            responsePromise: new AwaitableObject<NextTestStepResponse>()
        }

        console.log('getting next test step. sending message to actor')
        this.actor.sendMessage(m)
        console.log('getting next test step. sent message to actor')

        const result = await m.responsePromise.awaitResponse()

        console.log('getting next test step. got response from actor')
        return result
    }
}
