import {
    DigitSpanQuestion,
    DigitSpanResponse
} from "../../model/question_models";
import React, { useEffect, useState } from 'react';

import { NumericalKeyboardComponent } from "./NumericalKeyboardComponent"

interface Props {
    question: DigitSpanQuestion;
    onAnswer: (answer: DigitSpanResponse) => void;
}



function getVerbalInstructions(question: DigitSpanQuestion): string {
    const digitsText = question.displayedSequence.split('').join(', ');

    if (question.answerDigits === question.displayedSequence.length) {
        if (question.digitSpanType === 'forwardDigitSpan') {
            return "Remember this sequence in order: " + digitsText;
        }

        if (question.digitSpanType === 'backwardDigitSpan') {
            return "Remember this sequence in reverse order: " + digitsText;
        }

        if (question.digitSpanType === 'sequencingDigitSpan') {
            return "Remember this sequence and enter the numbers from lowest to highest: " + digitsText;
        }
    }
    else {
        if (question.digitSpanType === 'forwardDigitSpan') {
            return "Remember the last " + question.answerDigits + " digits of this sequence in order: " + digitsText;
        }

        if (question.digitSpanType === 'backwardDigitSpan') {
            return "Remember the last " + question.answerDigits + " digits of this sequence in reverse order: " + digitsText;
        }

        if (question.digitSpanType === 'sequencingDigitSpan') {
            return "Remember the last " + question.answerDigits + " digits and enter the numbers from lowest to highest: " + digitsText;
        }
    }

    return "remember this sequence " + digitsText;
}


export const DigitSpanQuestionComponent: React.FC<Props> = ({ question, onAnswer }) => {
    const [canAnswer, setCanAnswer] = useState<boolean>(false);
    const [answeredSequence, setAnsweredSequence] = useState<string>("");

    useEffect(() => {
        if (question.displayMode === 'verbal') {
            const instructions = getVerbalInstructions(question);

            const utterance = new SpeechSynthesisUtterance(instructions);

            // Select a voice
            const voices = speechSynthesis.getVoices();
            utterance.voice = voices[0]; // Choose a specific voice

            // Speak the text
            speechSynthesis.speak(utterance);

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            utterance.onend = function (event) {
                setCanAnswer(true);
            };
        }
    }, []);

    function appendDigit(digit: number) {
        const newAnsweredSequence = (answeredSequence + ("" + digit));

        if (newAnsweredSequence.length >= question.answerDigits) {
            const response: DigitSpanResponse = {
                type: 'digitSpan',
                questionId: question.questionId,
                duration: 0, // Placeholder, can be used to track duration
                timerExpired: false, // User selected, so timer didn't expire
                spanResponse: newAnsweredSequence
            }

            onAnswer(response);
        } else {
            setAnsweredSequence(newAnsweredSequence);
        }
    }

    function getSequenceInstructions() {
        if (question.answerDigits === question.displayedSequence.length) {
            if (question.digitSpanType === 'forwardDigitSpan') {
                return "Remember this sequence in order. ";
            }

            if (question.digitSpanType === 'backwardDigitSpan') {
                return "Remember this sequence in REVERSE order. ";
            }

            if (question.digitSpanType === 'sequencingDigitSpan') {
                return "Remember this sequence and enter the numbers from LOWEST TO HIGHEST.";
            }
        }
        else {
            if (question.digitSpanType === 'forwardDigitSpan') {
                return "Remember the last " + question.answerDigits + " digits of this sequence in order.";
            }

            if (question.digitSpanType === 'backwardDigitSpan') {
                return "Remember the last " + question.answerDigits + " digits of this sequence in REVERSE order.";
            }

            if (question.digitSpanType === 'sequencingDigitSpan') {
                return "Remember the last " + question.answerDigits + " digits and enter the numbers from LOWEST TO HIGHEST.";
            }
        }
    }

    if (!canAnswer) {
        return (<div>
            <p>Listen to the instructions</p>
            <p>{getSequenceInstructions()}</p>
        </div>)
    }

    return (
        <div>
            <p>{getSequenceInstructions()}</p>
            <NumericalKeyboardComponent onAnswerDigit={appendDigit} />
        </div>
    )
}