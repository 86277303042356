import {ApiClient} from "./apiClient";
import {Actor} from "../../helpers/actor";
import {GUID, Question, QuestionResponse, SurveyListItem, SurveyStartInfo} from '../../model/question_models';


export type InQuizState = | 'welcomePage' | 'inQuiz' | 'quizComplete'
export type QuizState = InQuizState | 'selectionScreen'

interface InSurveyQuizClientState {
    surveyInfo: SurveyStartInfo;
    pendingQuestions: Question[];
    currentQuestion?: Question;
    quizState: QuizState;
}

interface QuizClientState {
    availableSurveys: SurveyListItem[];
    currentSurvey: InSurveyQuizClientState | null;
}

function extractQuizState(state: QuizClientState) : QuizState {
    if (state.currentSurvey === null) {
        return 'selectionScreen';
    } else {
        return state.currentSurvey.quizState;
    }
}

function needsToQueryForMoreMessages(state: QuizClientState) : boolean {
    const qs = extractQuizState(state);

    if (qs === 'selectionScreen') {
        return false;
    }

    if (qs === 'welcomePage') {
        return false;
    }

    if (qs === 'quizComplete') {
        return false;
    }

    if (state.currentSurvey === null) {
        throw new Error('unexpected null current survey');
    }

    if (state.currentSurvey.pendingQuestions.length < 2) {
        return true;
    }

    return false;
}

interface StartQuiz {
    type: 'startQuizMessage'
    surveyId: GUID;
}

interface PostQuizAnswerMessage {
    type: 'postAnswerMessage'
    answer: QuestionResponse
}

interface SelectSurveyMessage {
    type: 'selectSurveyMessage';
    surveyId: GUID;
}

interface BackToWelcomePageMessage {
    type: 'backToWelcomePage'
}

interface PollForQuestions {
    type: 'pollForQuestions'
}

type Message = | StartQuiz | PostQuizAnswerMessage | SelectSurveyMessage | BackToWelcomePageMessage | PollForQuestions;

interface UpdateCoreResponse {
    state: QuizClientState
    remainingMessages: Message[]
}


function isQuestionInList(items: Question[], q: Question): boolean {
    for (const item of items) {
        if (item.questionId === q.questionId) {
            return true;
        }
    }

    return false;
}


function combineQuestions(existingQuestions: Question[], newQuestions: Question[]): Question[] {
    const result: Question[] = [...existingQuestions]

    for (const question of newQuestions) {
        if (!isQuestionInList(existingQuestions, question)) {
            result.push(question)
        }
    }

    return result;
}

async function updateQuizClientCore (apiClient: ApiClient, state: QuizClientState, messages: Message[], sendMessage: (m: Message) => void): Promise<UpdateCoreResponse> {
    console.log('updateCore - quiz client starting')
    console.log(`quiz client messages count: ${messages.length}`)

    const qs = extractQuizState(state);

    if (messages.length >= 1) {
        const message = messages[0]

        console.log(`quiz client message: ${message}`)
        switch (message.type)
        {
            case 'selectSurveyMessage':
                if (qs !== 'selectionScreen') {
                    throw new Error('Expected to be in selection screen');
                }

                state = {
                    ...state,
                    availableSurveys: [],
                    currentSurvey: {
                        surveyInfo: await apiClient.getSurveyInfo(message.surveyId),
                        pendingQuestions: [],
                        currentQuestion: undefined,
                        quizState: 'welcomePage'
                    }
                }

                break;
            case 'startQuizMessage':
                if (qs !== 'welcomePage') {
                    console.error('Cannot start the quiz when it has already started')
                    throw new Error('Cannot start the quiz when it has already started');
                }

                if (state.currentSurvey === null) {
                    throw new Error("expected in survey");
                }

                await apiClient.startSurvey(message.surveyId);
                state.currentSurvey.quizState = 'inQuiz';
                break;
            case 'backToWelcomePage':
                if (qs !== 'welcomePage') {
                    console.error('Cannot go back to welcome page if not currently in a quiz')
                    throw new Error('Cannot go back to welcome page if not currently in a quiz');
                }
                state.currentSurvey = null;
                break;
            case 'postAnswerMessage':
                if (state.currentSurvey === null) {
                    throw new Error("expected in survey");
                }

                if (state.currentSurvey.currentQuestion === undefined) {
                    throw new Error('No current question to answer');
                }

                apiClient.postQuestionResponseToServer(state.currentSurvey.surveyInfo.surveyId, message.answer);

                state.currentSurvey.currentQuestion = undefined;

                if (state.currentSurvey.pendingQuestions.length >= 1) {
                    state.currentSurvey.currentQuestion = state.currentSurvey.pendingQuestions[0]
                    state.currentSurvey.pendingQuestions.shift()
                }
                break;
            case 'pollForQuestions':
                break;
        }

        console.log('quizClient finished processing message')
        return {
            state: state,
            remainingMessages: messages.slice(1)
        };
    }

    console.log('checking for empty current question.')
    console.log(state)

    const pollMessage: PollForQuestions = {
        'type': 'pollForQuestions'
    }

    if (state.currentSurvey !== null) {
        if ((state.currentSurvey.currentQuestion === undefined) && (state.currentSurvey.pendingQuestions.length > 0)) {
            console.log('quiz client needs to set current question')
            state.currentSurvey.currentQuestion = state.currentSurvey.pendingQuestions[0];
            state.currentSurvey.pendingQuestions.shift();

            const response: UpdateCoreResponse = {
                state: state,
                remainingMessages: messages
            }

            if (response.remainingMessages.length === 0) {
                setTimeout(sendMessage, 500, pollMessage);
            }

            return response;
        }
    }

    if (qs === 'selectionScreen' && state.availableSurveys.length === 0) {
        const newState: QuizClientState = {
            ...state,
            availableSurveys: await apiClient.listAvailableSurveys()
        }

        return { state: newState, remainingMessages: [] }
    }

    if (needsToQueryForMoreMessages(state)) {
        if (state.currentSurvey === null) {
            throw "expected in survey"
        }

        console.log('quiz client needs to query for more questions')
        const nextStep = await apiClient.getNextTestStep(state.currentSurvey.surveyInfo.surveyId)

        if (nextStep.type === 'newQuestions') {
            console.log('quiz client got new questions')
            const newState: QuizClientState = {
                ...state,
                currentSurvey: {
                    ...state.currentSurvey,
                    pendingQuestions: combineQuestions(state.currentSurvey.pendingQuestions, nextStep.newQuestions)
                }
            }

            return { state: newState, remainingMessages: [] }
        } else if (nextStep.type === 'testComplete') {
            console.log('quiz client got end of test')
            const newState: QuizClientState = {
                ...state,
                currentSurvey: {
                    ...state.currentSurvey,
                    quizState: 'quizComplete'
                }
            }

            return { state: newState, remainingMessages: [] }
        } else {
            console.log(nextStep)
            throw "unhandled nextStep type"
        }
    }

    if (messages.length === 0) {
        console.log('nothing to do in quiz client state updater')
        return { state: state, remainingMessages: [] }
    }

    console.error('unknown or unhandled message')
    throw 'Unknown or unhandled message'
}

export class QuizClient {
    private readonly apiClient: ApiClient
    private readonly actor: Actor<QuizClientState, Message>

    constructor(apiClient: ApiClient, private onCurrentQuestionChangedCallback: () => void) {
        this.apiClient = apiClient
        this.postMessageToActor = this.postMessageToActor.bind(this); // Bind the method to the instance

        const initialState: QuizClientState = {
            availableSurveys: [],
            currentSurvey: null
        }

        const updaterFunc = (st: QuizClientState, messages: Message[]) => updateQuizClientCore(this.apiClient, st, messages, this.postMessageToActor);

        this.actor = new Actor<QuizClientState, Message>(initialState, updaterFunc, (state: QuizClientState) => this.onStateChangedHandler(state))
    }

    private postMessageToActor(message: Message) {
        if (this.actor === undefined) {
            setTimeout(this.postMessageToActor, 100, message)
        } else {
            this.actor.sendMessage(message);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onStateChangedHandler(state: QuizClientState) {
        console.log('quiz state changed')

        this.onCurrentQuestionChangedCallback()
    }

    getCurrentState() : QuizState {
        return extractQuizState(this.actor.getState())
    }

    listAvailableSurveys(): SurveyListItem[] {
        return this.actor.getState().availableSurveys;
    }

    getCurrentSurveyInfo(): SurveyStartInfo | null {
        const state = this.actor.getState();

        if (state.currentSurvey === null) {
            return null;
        }

        return state.currentSurvey?.surveyInfo
    }

    selectSurvey(surveyId: GUID) {
        const message: SelectSurveyMessage = {
            type: 'selectSurveyMessage',
            surveyId: surveyId
        }

        this.actor.sendMessage(message)
    }

    getCurrentQuestion() : (Question | null) {
        const qs = this.getCurrentState();

        if (qs !== 'inQuiz') {
            return null;
        }

        const currentQuestion = this.actor.getState().currentSurvey?.currentQuestion;

        if (currentQuestion === undefined) {
            return null;
        } else {
            return currentQuestion;
        }
    }

    startQuiz(surveyId: GUID) {
        const message:  StartQuiz = {
            type: 'startQuizMessage',
            surveyId: surveyId
        }

        this.actor.sendMessage(message)
    }

    backToWelcomeScreen() {
        const message: BackToWelcomePageMessage = {
            type: 'backToWelcomePage'
        }

        this.actor.sendMessage(message);
    }

    postQuizResponse(message: QuestionResponse) {
        const postAnswerMessage: PostQuizAnswerMessage = {
            type: 'postAnswerMessage',
            answer: message
        }

        this.actor.sendMessage(postAnswerMessage)
    }
}