export class AwaitableObject<T> {
    private resolve!: (value: T | PromiseLike<T>) => void;
    private reject!: (reason?: Error) => void;
    public promise: Promise<T>;

    constructor() {
        this.promise = new Promise<T>((res, rej) => {
            this.resolve = res;
            this.reject = rej;
        });
    }

    // This method will be called to set the response
    public setResponse(value: T): void {
        this.resolve(value);
    }

    // This method can be called to reject the promise
    public setError(reason?: Error): void {
        this.reject(reason);
    }

    // This method allows awaiting on the promise
    public async awaitResponse(): Promise<T> {
        return await this.promise;
    }
}