import React, { useState } from 'react';
import { NumericalAnswerQuestion, NumericalResponse } from '../../model/question_models';

interface Props {
    question: NumericalAnswerQuestion;
    onAnswer: (answer: NumericalResponse) => void;
}

const NumericalAnswerQuestionComponent: React.FC<Props> = ({ question, onAnswer }) => {
    const [enteredValue, setEnteredValue] = useState<number | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Validate the input according to the question's restrictions
    const validateValue = (value: number | undefined): string | null => {
        if (value === undefined) return null;

        const { min, max, decimalPlaces, integerOnly } = question.restrictions || {};

        if (min !== undefined && value < min) {
            return `The value must be at least ${min}.`;
        }

        if (max !== undefined && value > max) {
            return `The value must be at most ${max}.`;
        }

        if (integerOnly && !Number.isInteger(value)) {
            return `The value must be an integer.`;
        }

        if (decimalPlaces !== undefined && value.toString().split('.')[1]?.length > decimalPlaces) {
            return `The value must have at most ${decimalPlaces} decimal places.`;
        }

        return null; // No error
    };

    // Handle input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.valueAsNumber;
        setEnteredValue(value);

        const validationError = validateValue(value);
        setErrorMessage(validationError);
    };

    // Handle submit
    const handleSubmit = () => {
        if (!errorMessage && enteredValue !== undefined) {
            const response: NumericalResponse = {
                questionId: question.questionId,
                type: 'numericalAnswer',
                enteredValue: enteredValue,
                duration: 0, // Placeholder, you could add actual duration tracking
                timerExpired: false,
            };
            onAnswer(response);
        }
    };

    const inputStyle: React.CSSProperties = {
        width: '200px',
        height: '40px',
        fontSize: '16px',
        margin: '10px 0',
        padding: '5px',
        borderRadius: '5px',
        border: errorMessage ? '1px solid red' : '1px solid #ccc',
    };

    const errorStyle: React.CSSProperties = {
        color: 'red',
        marginTop: '5px',
    };

    return (
        <div>
            <input
                type="number"
                value={enteredValue !== undefined ? enteredValue : ''}
                onChange={handleInputChange}
                style={inputStyle}
                min={question.restrictions?.min}
                max={question.restrictions?.max}
            />
            {errorMessage && <div style={errorStyle}>{errorMessage}</div>}
            <button
                onClick={handleSubmit}
                disabled={errorMessage !== null || enteredValue === undefined}
                style={{ marginTop: '10px', padding: '10px 20px' }}
            >
                Submit
            </button>
        </div>
    );
};

export default NumericalAnswerQuestionComponent;
