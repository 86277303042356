import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { JSX } from 'react/jsx-runtime';
import {RankedItemsQuestion, RankedItemsResponse} from '../../model/question_models';

interface RankedItemsQuestionComponentProps {
    question: RankedItemsQuestion;
    onAnswer: (answer: RankedItemsResponse) => void;  // Callback for submitting the answer
}

type DroppableProvidedItem = {
    droppableProps: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLUListElement> & React.HTMLAttributes<HTMLUListElement>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    innerRef: React.LegacyRef<HTMLUListElement> | undefined; placeholder: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined;
};

const RankedItemsQuestionComponent: React.FC<RankedItemsQuestionComponentProps> = ({question, onAnswer}) => {
    const [items, setItems] = useState(question.items);  // Use the items from the question

    // Handle drag end event
    const handleOnDragEnd = (result: { destination: { index: number; }; source: { index: number; }; }) => {
        if (!result.destination) return;

        const updatedItems = Array.from(items);
        const [reorderedItem] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, reorderedItem);

        setItems(updatedItems);
    };

    // Handle submit button click
    const handleSubmit = () => {
        // Extract the ranked item IDs (GUIDs)
        const rankedItems = items.map(item => item.id);
        const response: RankedItemsResponse = {
            questionId: question.questionId,
            type: 'rankedItems',
            duration: 0, // Placeholder, can be used to track duration
            timerExpired: false, // User selected, so timer didn't expire
            rankedItems: rankedItems
        }

        // Submit the ranked GUIDs to the parent component
        onAnswer(response);
    };

    return (
        <div>
            <h3 style={{color: 'black'}}>{question.text}</h3>  {/* Ensure text is black */}
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="items">
                    {(provided: DroppableProvidedItem) => (
                        <ul {...provided.droppableProps} ref={provided.innerRef} style={{ listStyle: 'none', padding: 0 }}>
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided: { innerRef: React.LegacyRef<HTMLLIElement> | undefined; draggableProps: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLLIElement> & React.LiHTMLAttributes<HTMLLIElement>; dragHandleProps: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLLIElement> & React.LiHTMLAttributes<HTMLLIElement>; }) => (
                                        <li
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                color: 'black',  // Ensure ranked items' text is black
                                                padding: '10px',
                                                margin: '5px 0',
                                                backgroundColor: '#f8f8f8',
                                                border: '1px solid #ddd',
                                                borderRadius: '4px',
                                                ...provided.draggableProps.style
                                            }}
                                        >
                                            {item.text}
                                        </li>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
            <button onClick={handleSubmit} style={{ marginTop: '10px' }}>Submit Ranking</button>
        </div>
    );
};

export default RankedItemsQuestionComponent;
