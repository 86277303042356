// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function areEquivalent(a: any, b: any): boolean {
    // Check if both are strictly equal for primitive types and function references
    if (a === b) return true;

    if (a === undefined) return false;
    if (b === undefined) return false;

    // If they are not of the same type, they are not equal
    if (typeof a !== typeof b) return false;

    // Check if both are arrays (lists)
    if (Array.isArray(a) && Array.isArray(b)) {
        if (a.length !== b.length) return false;
        return a.every((item, index) => areEquivalent(item, b[index]));
    }

    // Check if both are objects (dictionaries)
    if (typeof a === 'object' && a !== null && b !== null) {
        const aKeys = Object.keys(a);
        const bKeys = Object.keys(b);

        // Ensure both have the same number of keys
        if (aKeys.length !== bKeys.length) return false;

        // Ensure all keys and their values are the same
        return aKeys.every(key => Object.prototype.hasOwnProperty.call(b, key) && areEquivalent(a[key], b[key]));
    }

    // If none of the above, return false (this covers cases like different types)
    return false;
}

