import React from 'react';
import { SurveyListItem } from "../../model/question_models";

// Define the component props
interface SurveyListProps {
    surveys: SurveyListItem[];
    onSurveyStart: (surveyId: string) => void;
}

export const SurveyList: React.FC<SurveyListProps> = ({ surveys, onSurveyStart }) => {
    return (
        <div>
            <h1>
                Test Selection Screen
            </h1>
            <p>
                Select which test you want to do from this list.
            </p>
            {surveys.map(survey => (
                <button
                    key={survey.surveyId}
                    onClick={() => onSurveyStart(survey.surveyId)}
                    style={{ margin: '5px' }}
                >
                    {survey.title}
                </button>
            ))}
        </div>
    );
};

export default SurveyList;
