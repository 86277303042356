import React, {useEffect, useState} from "react";


export const NoQuestionsAvailableComponent: React.FC = () => {
    const [hasWaitedState, setHasWaitedState] = useState<number>(0);

    useEffect(() => {
        setTimeout(() => setHasWaitedState(1), 2500);
        setTimeout(() => setHasWaitedState(2), 10000);
    }, []);

    if (hasWaitedState === 0) {
        return (
            <div>
                <p>Please wait</p>
            </div>
        )
    }

    if (hasWaitedState === 1) {
        return (
            <div>
                <p>Waiting for more questions</p>
                <p>Waiting for the server to respond</p>
            </div>
        )
    }

    return (
        <div>
            <p>Waiting for more questions</p>
            <p>Check your internet connection and try refreshing the page</p>
        </div>
    )
}
