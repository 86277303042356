import React, { useState } from 'react';
import { MultipleChoiceQuestion, MultipleChoiceResponse, GUID } from '../../model/question_models';

interface Props {
    question: MultipleChoiceQuestion;
    onAnswer: (answer: MultipleChoiceResponse) => void;
}

const MultipleChoiceQuestionComponent: React.FC<Props> = ({ question, onAnswer }) => {
    const [selectedChoiceId, setSelectedChoiceId] = useState<GUID | undefined>(undefined);

    // Handle the selection of a choice for the button
    const handleChoiceSelect = (choiceId: GUID) => {
        const response: MultipleChoiceResponse = {
            questionId: question.questionId,
            type: 'multipleChoice',
            selectedChoiceId: choiceId,
            duration: 0, // Placeholder for tracking time taken
            timerExpired: false,
        };
        onAnswer(response);
    };

    // Handle combobox change (selection doesn't submit, but sets the value)
    const handleComboBoxChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const choiceId = e.target.value as GUID;
        setSelectedChoiceId(choiceId);
    };

    // Handle submit for combobox selection
    const handleSubmit = () => {
        if (selectedChoiceId) {
            const response: MultipleChoiceResponse = {
                questionId: question.questionId,
                type: 'multipleChoice',
                selectedChoiceId: selectedChoiceId,
                duration: 0, // Placeholder for tracking time taken
                timerExpired: false,
            };
            onAnswer(response);
        }
    };

    // Styles for the buttons and combobox
    const buttonStyle: React.CSSProperties = {
        width: '200px',
        height: '50px',
        fontSize: '16px',
        margin: '10px 0',
        cursor: 'pointer',
        borderRadius: '5px',
        border: '1px solid #ccc',
    };

    const listStyle: React.CSSProperties = {
        listStyleType: 'none',
        padding: 0,
    };

    const comboBoxStyle: React.CSSProperties = {
        width: '220px',
        height: '40px',
        fontSize: '16px',
        margin: '10px 0',
        padding: '5px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    };

    return (
        <div>
            {/* Conditionally render buttons or combobox based on number of choices */}
            {question.choices.length <= 10 ? (
                <ul style={listStyle}>
                    {question.choices.map((choice) => (
                        <li key={choice.id}>
                            <button
                                style={buttonStyle}
                                onClick={() => handleChoiceSelect(choice.id)} // Auto-submit when clicked
                            >
                                {choice.text}
                            </button>
                        </li>
                    ))}
                </ul>
            ) : (
                <div>
                    <select
                        style={comboBoxStyle}
                        onChange={handleComboBoxChange}
                        value={selectedChoiceId || ''}
                    >
                        <option value="" disabled>
                            Select an option
                        </option>
                        {question.choices.map((choice) => (
                            <option key={choice.id} value={choice.id}>
                                {choice.text}
                            </option>
                        ))}
                    </select>
                    <button
                        onClick={handleSubmit}
                        disabled={!selectedChoiceId} // Disable until a choice is selected
                        style={buttonStyle}
                    >
                        Submit
                    </button>
                </div>
            )}
        </div>
    );
};

export default MultipleChoiceQuestionComponent;
