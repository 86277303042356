import { areEquivalent } from "./equality";


export class Actor<State, Message> {
    private queue: Message[] = [];
    private state: State;
    private isProcessing = false;
    private readonly stateChangedCallback: (state: State) => void;

    constructor(initialState: State, private updater: (state: State, messages: Message[]) => Promise<{ state: State, remainingMessages: Message[] }>, private onStateChanged: (state: State) => void) {
        this.state = initialState;
        this.sendMessage = this.sendMessage.bind(this); // Bind the method to the instance
        this.stateChangedCallback = onStateChanged
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.processMessages().then(_unused => {}); // Automatically start processing on startup
    }

    public sendMessage(message: Message): void {
        this.queue.push(message);
        this.processMessages();
    }

    // Main loop to process messages in the queue
    private async processMessages(): Promise<void> {
        if (this.isProcessing) return; // Avoid concurrent execution

        console.log('resuming actor')

        this.isProcessing = true;

        while (this.queue.length > 0 || !this.queue.length) { // Continue until all messages are processed or startup is complete
            console.log('processing actor')

            await this.internalProcessMessagesWhileStateChanged();

            // If there are new messages in the queue, continue processing
            if (this.queue.length === 0) {
                console.log('no more messages left')
                break; // Exit the loop if no more messages are left
            }
        }

        console.log('done processing actor')
        this.stateChangedCallback(this.state)
        console.log('done with callback')

        this.isProcessing = false;
    }

    private async internalProcessMessagesWhileStateChanged(): Promise<void> {
        // eslint-disable-next-line no-constant-condition
        while(true) {
            const originalState = this.state;
            const originalMessageCount = this.queue.length;

            console.log('processing state')
            await this.internalProcessMessages()
            console.log('processed state')
            console.log(this.state)

            if (this.queue.length === originalMessageCount && areEquivalent(this.state, originalState)) {
                console.log('state unchanged after processing')
                break;
            } else {
                console.log('state changed. looping')
            }
        }
    }

    private async internalProcessMessages(): Promise<void> {
        const currentQueue = [...this.queue]; // Take a snapshot of the current queue
        this.queue = []; // Clear the queue for new incoming messages while processing

        try {
            const result = await this.updater(this.state, currentQueue);

            if (result.state === undefined) {
                throw new Error('updater returned undefined state');
            }

            this.state = result.state; // Update the state
            this.queue.push(...result.remainingMessages); // Requeue remaining messages
        } catch (error) {
            console.error("Error processing messages, retrying...", error);
            // In case of failure, requeue all unprocessed messages
            this.queue.unshift(...currentQueue);
            await new Promise(resolve => setTimeout(resolve, 1000)); // Optional: Delay before retrying
        }
    }

    // A simple function to get the current state
    public getState(): State {
        return this.state;
    }
}
