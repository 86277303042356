import { GUID, Question, QuestionResponse, SurveyListItem, SurveyStartInfo } from '../../model/question_models';


// Import the necessary types for URLSearchParams and Fetch API
export async function getSessionIdFromServer(urlParams: URLSearchParams): Promise<string> {
    // Convert the URLSearchParams to a query string
    const queryString = urlParams.toString();

    // Call the server to get the session_id
    const response = await fetch(`/api/session?${queryString}`);

    if (!response.ok) {
        throw new Error('Failed to fetch session ID');
    }

    const data = await response.json();

    return data.session_id; // Extract and return the session_id
}

export async function getAvailableSurveys(sessionId: string): Promise<SurveyListItem[]> {
    console.log('start getting available surveys')

    const response = await fetch(`/api/inventories?session_id=${sessionId}`);

    if (!response.ok) {
        console.log('failed to get available surveys')
        throw new Error('Failed to fetch available surveys');
    }

    const data = await response.json();
    console.log('got available surveys')
    return data;
}

export async function getSurveyInfo(sessionId: string, surveyId: GUID): Promise<SurveyStartInfo> {
    console.log('start getting survey info')

    const response = await fetch(`/api/inventories/${surveyId}?session_id=${sessionId}`);

    if (!response.ok) {
        console.log('failed to get survey info')
        throw new Error('Failed to get survey info');
    }

    const data = await response.json();
    console.log('got survey info')
    return data;
}

export async function startSurvey(sessionId: string, surveyId: GUID): Promise<SurveyStartInfo> {
    console.log('starting survey')

    const response = await fetch(
        `/api/inventories/${surveyId}/start?session_id=${sessionId}`,
        {
            method: 'POST'
        });

    if (!response.ok) {
        console.log('failed to start survey')
        throw new Error('Failed to start survey');
    }

    const data = await response.json();
    console.log('started survey')
    return data;
}

// Function to get questions using session_id
export async function getQuestions(sessionId: string, surveyId: string): Promise<Question[]> {
    console.log('getting questions')

    const response = await fetch(`/api/inventories/${surveyId}/questions?session_id=${sessionId}`);

    if (!response.ok) {
        console.log('failed to get questions')
        throw new Error('Failed to fetch questions');
    }

    const data = await response.json();
    console.log('got questions')
    return data;
}

// Function to post answers with session_id
export async function postAnswers(sessionId: string, surveyId: string, answers: QuestionResponse[]): Promise<void> {
    console.log('posting answers')

    const response = await fetch(`/api/inventories/${surveyId}/responses?session_id=${sessionId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(answers),
    });

    if (!response.ok) {
        console.log('failed to post answers')
        throw new Error('Failed to submit answers');
    }

    console.log('posted answers')
    const data = await response.json();
    console.log('Answers submitted successfully:', data.message);
}
