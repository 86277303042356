import React from 'react';
import { LikertRatingQuestion, LikertRatingResponse } from '../../model/question_models';

interface Props {
    question: LikertRatingQuestion;
    onAnswer: (answer: LikertRatingResponse) => void;
}

const LikertRatingQuestionComponent: React.FC<Props> = ({ question, onAnswer }) => {

    // Handle the selection of a Likert rating
    const handleChoiceSelect = (selectedRating: 'stronglyDisagree' | 'disagree' | 'neutral' | 'agree' | 'stronglyAgree') => {
        const response: LikertRatingResponse = {
            questionId: question.questionId,
            type: 'likert',
            selectedRating: selectedRating,
            duration: 0, // Placeholder, can be used to track duration
            timerExpired: false, // User selected, so timer didn't expire
        };
        onAnswer(response);
    };

    // Styles for the buttons
    const buttonStyle: React.CSSProperties = {
        width: '180px',       // Set a fixed width for all buttons
        height: '50px',       // Make buttons taller
        fontSize: '14px',     // Font size for longer text
        margin: '0 5px',      // Horizontal margin between buttons
        cursor: 'pointer',    // Pointer cursor for better UX
        borderRadius: '5px',  // Rounded corners
        border: '1px solid #ccc', // Border styling
        whiteSpace: 'nowrap', // Prevent wrapping of button text
    };

    const containerStyle: React.CSSProperties = {
        display: 'flex',      // Horizontal layout for buttons
        justifyContent: 'center',  // Center the buttons horizontally
        alignItems: 'center', // Align items vertically centered
        margin: '20px 0',     // Space above and below the buttons
        flexWrap: 'wrap',     // Wrap if necessary for smaller screens
    };

    return (
        <div>
            <div style={containerStyle}>
                <button
                    style={buttonStyle}
                    onClick={() => handleChoiceSelect('stronglyDisagree')}
                >
                    Strongly Disagree
                </button>
                <button
                    style={buttonStyle}
                    onClick={() => handleChoiceSelect('disagree')}
                >
                    Disagree
                </button>
                <button
                    style={buttonStyle}
                    onClick={() => handleChoiceSelect('neutral')}
                >
                    Neutral
                </button>
                <button
                    style={buttonStyle}
                    onClick={() => handleChoiceSelect('agree')}
                >
                    Agree
                </button>
                <button
                    style={buttonStyle}
                    onClick={() => handleChoiceSelect('stronglyAgree')}
                >
                    Strongly Agree
                </button>
            </div>
        </div>
    );
};

export default LikertRatingQuestionComponent;
