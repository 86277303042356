import React, { useEffect, useState } from 'react';
import { Question, QuestionResponseBase } from '../../model/question_models';

import MultipleChoiceQuestionComponent from './MultipleChoiceQuestionComponent';
import YesNoUnsureQuestionComponent from './YesNoUnsureQuestionComponent';
import LikertRatingQuestionComponent from './LikertRatingQuestionComponent';
import NumericalAnswerQuestionComponent from './NumericalAnswerQuestionComponent';
import StringAnswerQuestionComponent from './StringAnswerQuestionComponent';
import RankedItemsQuestionComponent from './RankedItemsQuestionComponent';
import {DigitSpanQuestionComponent} from "./DigitSpanQuestionComponent";

interface QuestionProps {
    question: Question;
    onAnswer: (answer: QuestionResponseBase) => void;
}

const QuestionComponent: React.FC<QuestionProps> = ({ question, onAnswer }) => {
    const [startTime, setStartTime] = useState(Date.now());
    const [timeLeft, setTimeLeft] = useState<number | undefined>(question.timeLimit ? question.timeLimit * 1000 : undefined); // Milliseconds

    useEffect(() => {
        setStartTime(Date.now()); // Reset start time for new question
        setTimeLeft(question.timeLimit ? question.timeLimit * 1000 : undefined); // Reset time left for new question
    }, [question]);

    useEffect(() => {
        if (timeLeft === undefined) return;

        const updateTimeLeft = () => {
            const now = Date.now();
            const elapsed = now - startTime;
            const totalTime = question.timeLimit ? question.timeLimit * 1000 : 0;
            const newTimeLeft = totalTime - elapsed;

            if (newTimeLeft <= 0) {
                setTimeLeft(0);
                const response: QuestionResponseBase = {
                    questionId: question.questionId,
                    duration: totalTime,
                    timerExpired: true
                };
                onAnswer(response); // Automatically submit the answer when time expires
            } else {
                setTimeLeft(newTimeLeft);
            }
        };

        const timer = setInterval(updateTimeLeft, 100); // Update every 100 ms

        return () => clearInterval(timer);
    }, [startTime, question.questionId, question.timeLimit, timeLeft, onAnswer]);

    // Intercept and handle the answer
    const handleAnswer = (answer: QuestionResponseBase) => {
        const now = Date.now();
        const elapsed = now - startTime;
        const totalTime = question.timeLimit ? question.timeLimit * 1000 : 0;

        const updatedAnswer = {
            ...answer,
            duration: elapsed, // Set the actual time taken to answer
            timerExpired: totalTime > 0 && elapsed >= totalTime // Check if the timer expired
        };

        onAnswer(updatedAnswer);
    };

    const renderQuestionInput = () => {
        switch (question.type) {
            case 'multipleChoice':
                return (
                    <MultipleChoiceQuestionComponent
                        question={question}
                        onAnswer={handleAnswer} // Intercept and calculate the time
                    />
                );
            case 'yesNoUnsure':
                return (
                    <YesNoUnsureQuestionComponent
                        question={question}
                        onAnswer={handleAnswer}
                    />
                );
            case 'likert':
                return (
                    <LikertRatingQuestionComponent
                        question={question}
                        onAnswer={handleAnswer}
                    />
                );
            case 'numericalAnswer':
                return (
                    <NumericalAnswerQuestionComponent
                        question={question}
                        onAnswer={handleAnswer}
                    />
                );
            case 'stringAnswer':
                return (
                    <StringAnswerQuestionComponent
                        question={question}
                        onAnswer={handleAnswer}
                    />
                );
            case 'rankedItems':
                return (
                    <RankedItemsQuestionComponent
                        question={question}
                        onAnswer={handleAnswer}
                    />
                );
            case 'digitSpan':
                return (
                    <DigitSpanQuestionComponent question={question} onAnswer={handleAnswer} />
                );
            default:
                return null;
        }
    };

    // Calculate progress for the progress bar
    const totalTime = question.timeLimit ? question.timeLimit * 1000 : 0; // Total time in ms
    const progress = totalTime > 0 ? ((totalTime - (timeLeft ?? 0)) / totalTime) * 100 : 0;

    return (
        <div>
            {/* Conditionally render the header if there is a countdown */}
            {timeLeft !== undefined && (
                <header style={headerStyle}>
                    <progress value={progress} max="100" style={progressBarStyle} />
                    <span style={timeLeftStyle}>{Math.ceil(timeLeft / 1000)} seconds</span>
                </header>
            )}

            {/* Render question input */}
            <div style={contentStyle}>
                <h3>{question.text}</h3>
                {renderQuestionInput()}
            </div>
        </div>
    );
};

// CSS styles for the header, progress bar, and time left display
const headerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    padding: '10px',
    backgroundColor: '#f8f9fa',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1000
};

const progressBarStyle: React.CSSProperties = {
    width: '80%', // Progress bar width
    height: '20px',
};

const timeLeftStyle: React.CSSProperties = {
    fontSize: '16px',
    color: '#333',
    marginRight: '20px', // Add margin to avoid cutting off the text
};

const contentStyle: React.CSSProperties = {
    paddingTop: '60px', // Offset the fixed header
    padding: '20px',
};

export default QuestionComponent;
