import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { SurveyList } from "./client/survey_list/SurveyList"
import { SurveyStartScreen } from "./client/survey_list/SurveyStartScreen"
import { QuizClient } from "./client/client_lib/quizClient";
import { ApiClient } from "./client/client_lib/apiClient"
import { GUID, Question, QuestionResponse, SurveyListItem, SurveyStartInfo} from './model/question_models';
import QuestionComponent from './client/questions_components/QuestionComponent';
import { NoQuestionsAvailableComponent } from './client/questions_components/NoQuestionsAvailableComponent';


function App() {
    const quizClientRef = useRef<QuizClient | null>(null);
    const [question, setQuestion] = useState<Question | null>(null);
    const [listSurveys, setListSurveys] = useState<SurveyListItem[]>([]);
    const [startScreenInfo, setStartScreenInfo] = useState<SurveyStartInfo | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const updateStateFromQuizClient = () => {
        const quizClient = quizClientRef.current;

        if (quizClient === null) {
            console.log('ignoring updatestate for quiz client because it is null');
            return;
        }

        const currentState = quizClient.getCurrentState();

        console.log(`currentState: ${currentState}`)

        function handleInQuiz() {
            if (quizClient === null) {
                return;
            }

            const currentQuestion = quizClient.getCurrentQuestion();
            console.log(`inQuiz question=${currentQuestion}`)
            setError(null);
            setQuestion(currentQuestion);
            if (currentQuestion === null) {
                setLoading(true);
            } else {
                setLoading(false);
            }
            setListSurveys([])
            setStartScreenInfo(null)
        }

        switch (currentState)
        {
            case 'selectionScreen':
                setError(null);
                setLoading(false);
                setQuestion(null);
                setListSurveys(quizClient.listAvailableSurveys())
                setStartScreenInfo(null)
                break;
            case 'welcomePage':
                setError(null);
                setLoading(false);
                setQuestion(null);
                setListSurveys([])
                setStartScreenInfo(quizClient.getCurrentSurveyInfo())
                break;
            case 'inQuiz':
                handleInQuiz();
                break;
            case 'quizComplete':
                setLoading(true);
                setError(null);
                setQuestion(null);
                setListSurveys([])
                setStartScreenInfo(null)
                break;
        }

    }

    // On app load, fetch session_id and questions
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const apiClient = new ApiClient(urlParams);
        const qClient = new QuizClient(apiClient, updateStateFromQuizClient);

        quizClientRef.current = qClient;
    }, []);

    // Function to submit answers
    const handleSubmitAnswer = (answer: QuestionResponse) => {
        const quizClient = quizClientRef.current;

        if (quizClient === null) {
            console.error('Cannot submit answer because quiz client is undefined')
            return;
        }

        // Post the answer to the server
        try {
            quizClient.postQuizResponse(answer)
        } catch (err) {
            console.error('Error submitting answer:', err);
        }
    };

    const handleSelectSurvey = (surveyId: GUID) => {
        const quizClient = quizClientRef.current;

        if (quizClient === null) {
            console.error('Cannot submit answer because quiz client is undefined')
            return;
        }

        quizClient.selectSurvey(surveyId);
    }

    const handleBackToMainScreen = () => {
        const quizClient = quizClientRef.current;

        if (quizClient === null) {
            console.error('Cannot submit answer because quiz client is undefined')
            return;
        }

        quizClient.backToWelcomeScreen();
    };

    const handleStartSurvey = () => {
        const quizClient = quizClientRef.current;

        if (quizClient === null) {
            console.error('Cannot submit answer because quiz client is undefined')
            return;
        }

        if (startScreenInfo === null) {
            console.error('Cannot start survey with survey undefined')
            return;
        }

        quizClient.startQuiz(startScreenInfo.surveyId);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (listSurveys.length > 0) {
        return (
            <div className="App">
                <header className="App-header">
                    <SurveyList surveys={listSurveys}
                                onSurveyStart={handleSelectSurvey}/>
                </header>
            </div>
        )
    }

    if (startScreenInfo !== null) {
        return (
            <div className="App">
                <header className="App-header">
                    <SurveyStartScreen survey={startScreenInfo}
                                       onStart={handleStartSurvey}
                                       onCancel={handleBackToMainScreen}/>
                </header>
            </div>
        )
    }

    return (
        <div className="App">
            <header className="App-header">
                {/* Render the current question */}
                {(question !== undefined && question !== null) ? (
                    <QuestionComponent
                        question={question}
                        onAnswer={handleSubmitAnswer}
                    />
                ) : (
                    <NoQuestionsAvailableComponent />
                )}
            </header>
        </div>
    );
}

export default App;
